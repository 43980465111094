import Cropper from "cropperjs";
import Modal from "bootstrap/js/dist/modal";
import { SocialPhotoImporter, SocialSource } from "./SocialPhotoImporter";
import StockPhotoModal from "./StockPhotoModal";
import { sendBrowserAgnosticEvent } from "../core/utils";
import { postMultipartWithProgress } from "../memorials/utils";

const MAX_PHOTOS = 8;
const CROPPER_OPTIONS = {
  aspectRatio: 4 / 3,
  autoCrop: true,
  autoCropArea: 0.8,
  viewMode: 0,
  cropBoxMovable: false,
  cropBoxResizable: false,
  dragMode: "move",
  minCropBoxWidth: 200,
  minCropBoxHeight: 150,
  minCanvasHeight: 150,
  zoomOnWheel: true,
};

/**
 * Verify that the type provided matches the accept attribute provided.
 *
 * @param {string} type - the type to test
 * @param {string} accept - the accept attribute value to test against
 * @returns {boolean} - true if the type matches the accept
 */
const verifyAccept = (type, accept) => {
  const allowed = accept.split(",").map((x) => x.trim());
  return allowed.includes(type) || allowed.includes(`${type.split("/")[0]}/*`);
};

export default class DeceasedPhotoUploader {
  constructor(props) {
    this.isOnboarding = props.isOnboarding;
    this.container = document.getElementById("deceased-photo-modal");

    this.socialImporter = new SocialPhotoImporter(
      document.getElementById("social-photo-importer"),
      (photos) => {
        this.onSocialPhotosSelected(photos);
      },
      MAX_PHOTOS,
    );

    const stockPhotoRoot = document.getElementById("stock-photo-importer");
    if (stockPhotoRoot) {
      this.stockPhotoModal = new StockPhotoModal(stockPhotoRoot, (stockPhotoData) => {
        this.stockPhotosSelected(stockPhotoData);
      });
    }

    this.isSelectingPhotos = true;
    this.isInExternalPhotoSelection = false;

    this.ingestionOptionsModal = document.getElementById("photo-ingestion-modal");
    this.fileInput = this.container.querySelector('input[type="file"]');
    this.fileInput.addEventListener("change", () => {
      this.filesSelected(this.fileInput.files);
    });

    this.selectPhotosButton = this.ingestionOptionsModal.querySelector(
      ".ingestion-option.upload",
    );
    this.selectPhotosButton.addEventListener("click", () => {
      this.fileInput.click();
    });

    this.importFromFacebookButton = this.ingestionOptionsModal.querySelector(
      ".ingestion-option.facebook",
    );
    if (this.importFromFacebookButton) {
      this.importFromFacebookButton.addEventListener("click", () => {
        this.isInExternalPhotoSelection = true;
        this.render();

        this.socialImporter.show(SocialSource.FACEBOOK);
      });
    }

    this.importFromEverLovedButton = this.ingestionOptionsModal.querySelector(
      ".ingestion-option.stock-photos",
    );
    if (this.importFromEverLovedButton) {
      this.importFromEverLovedButton.addEventListener("click", () => {
        this.isInExternalPhotoSelection = true;
        this.render();
        this.stockPhotoModal.show(
          this.photos
            .filter((p) => p.hashid)
            .map((p) => ({ thumbnailUrl: p.url, hashid: p.hashid })),
          this.collageSize - this.photos.length,
        );
      });
    }

    this.layoutFrame = this.container.querySelector(".layout-frame");
    this.layoutFrame.addEventListener("click", (e) => {
      // We are clicking a layout-selector element.
      const layoutSelector = e.target.closest(".layout-selector");
      if (layoutSelector !== null) {
        this.collageSize = parseInt(layoutSelector.dataset.layoutSize, 10);
        this.isSelectingLayout = false;
        this.isSelectingPhotos = this.photos.length === 0;

        this.render();
      }
    });

    this.filterFrame = this.container.querySelector(".filter-frame");
    this.filterFinishedButton = this.filterFrame.querySelector(".filter-finished");
    this.filterFinishedButton.addEventListener("click", () => {
      // Filtering should be done at this point, so we just need to trigger a render
      // so that it moves to the preview frame.
      this.isFiltering = false;
      this.render();
    });

    this.previewFrame = this.container.querySelector(".preview-frame");

    this.existingPhotos = props.existingPhotos || [];
    this.photos = this.existingPhotos.slice();

    this.cropperData = {};

    this.isShowing = false;

    this.saveButton = this.previewFrame.querySelector(".save-photos-btn");
    this.saveButton.addEventListener("click", () => {
      if (!this.saveButton.disabled) {
        this.uploadPhotos();
      }
    });
    this.onPhotosUpdated = props.onPhotosUpdated;

    this.uploadUrl = props.uploadUrl;
    this.uploadExtraParams = {
      is_onboarding: props.isOnboarding,
      fundraising_purpose: props.fundraisingPurpose,
    };

    this.uploadOverlay = this.container.querySelector(".upload-overlay");

    this.nextUrl = props.nextUrl;

    const cancelButton = this.container.querySelector(".cancel-photo-selection");
    cancelButton.addEventListener("click", () => {
      this.photos = this.existingPhotos;
      this.cropperData = {};
      const previewPhotosContainer = this.previewFrame.querySelector(".preview-photos");

      while (previewPhotosContainer.hasChildNodes()) {
        previewPhotosContainer.removeChild(previewPhotosContainer.lastChild);
      }

      this.hide();
    });

    this.collageSize = null;

    this.incompleteWarning = this.previewFrame.querySelector(".incomplete-warning");
    this.incompleteWarning.classList.toggle("d-none", this.isOnboarding);

    this.incompleteWarning
      .querySelector(".invoke-stock-photo-selector")
      .addEventListener("click", () => {
        this.isSelectingPhotos = true;
        this.isInExternalPhotoSelection = true;
        this.render();
        this.stockPhotoModal.show(
          this.photos
            .filter((p) => p.hashid)
            .map((p) => ({ thumbnailUrl: p.url, hashid: p.hashid })),
        );
      });

    this.previewFrame
      .querySelector(".select-different-layout")
      .addEventListener("click", () => {
        this.collageSize = null;
        this.isSelectingLayout = true;
        this.isSelectingPhotos = false;

        if (this.socialImporter.activeImporter) {
          this.socialImporter.reset();
        }

        this.render();
      });

    this.previewFrame.querySelector(".upload-photo").addEventListener("click", () => {
      this.startSelectPhotos();
    });
  }

  /**
   * Show the uploader.
   *
   * @param {boolean} [overrideSelectingPhotos] - if true, then autoprogress the render
   *     state to be isSelectingPhotos=true
   */
  show(overrideSelectingPhotos = false) {
    this.isShowing = true;
    this.isInExternalPhotoSelection = false;

    this.isSelectingLayout = this.collageSize === null;
    this.isSelectingPhotos =
      (this.photos.length === 0 || overrideSelectingPhotos) && !this.isSelectingLayout;

    if (this.socialImporter.activeImporter) {
      this.socialImporter.reset();
    }

    this.render();
  }

  /**
   * Hide the uploader.
   */
  hide() {
    this.isShowing = false;
    this.isSelectingLayout = false;
    this.isSelectingPhotos = false;
    this.isInExternalPhotoSelection = false;
    this.collageSize = null;

    if (this.socialImporter.activeImporter) {
      this.socialImporter.reset();
    }

    this.render();
  }

  /**
   * Make a render passed based on our state.
   */
  render() {
    const modal = Modal.getOrCreateInstance(this.container);
    const socialOptionsModal = Modal.getOrCreateInstance(this.ingestionOptionsModal);

    const previewPhotosContainer = this.previewFrame.querySelector(".preview-photos");
    if (previewPhotosContainer) {
      previewPhotosContainer.dataset.collageSize = this.collageSize;

      if (this.isOnboarding) {
        previewPhotosContainer.classList.add("onboarding");
      }
    }

    this.container.querySelectorAll(".layout-size").forEach((span) => {
      span.innerText = `${this.collageSize}`;
    });

    if (this.isShowing) {
      if (this.isSelectingPhotos) {
        if (this.isInExternalPhotoSelection) {
          modal.hide();
          socialOptionsModal.hide();
        } else {
          modal.hide();
          socialOptionsModal.show();
        }
      } else {
        modal.show();
        socialOptionsModal.hide();

        if (this.isSelectingLayout) {
          this.filterFrame.classList.add("d-none");
          this.previewFrame.classList.add("d-none");
          this.layoutFrame.classList.remove("d-none");
          this.renderLayoutFrame();
        } else if (!this.photos.length && !this.existingPhotos.length) {
          this.filterFrame.classList.add("d-none");
          this.previewFrame.classList.add("d-none");
          this.layoutFrame.classList.add("d-none");
        } else if (this.collageSize !== null && this.photos.length > this.collageSize) {
          this.filterFrame.classList.remove("d-none");
          this.previewFrame.classList.add("d-none");
          this.layoutFrame.classList.add("d-none");
          this.isFiltering = true;
          this.renderFilterFrame();
        } else {
          this.filterFrame.classList.add("d-none");
          this.previewFrame.classList.remove("d-none");
          this.layoutFrame.classList.add("d-none");
          this.renderPreviewFrame();
        }
      }
    } else {
      modal.hide();
      socialOptionsModal.hide();
      this.isFiltering = false;
    }
  }

  /**
   * Called when stock photos have been selected.
   *
   * @param {Array} stockPhotosData - an array of stock photo data objects
   */
  stockPhotosSelected(stockPhotosData) {
    stockPhotosData.forEach((stockPhoto) => {
      if (this.photos.filter((f) => f.hashid === stockPhoto.hashid).length === 0) {
        this.photos.push(stockPhoto);
      }
    });

    if (this.photos.length) {
      this.isSelectingPhotos = false;
    }

    this.render();
  }

  /**
   * Called when uploadable files have been selected.
   *
   * @param {FileList} files - the FileList from the HTMLFileInputElement.
   */
  filesSelected(files) {
    // Push onto state but don't allow duplicates.
    Array.from(files).forEach((file) => {
      if (
        this.photos.filter(
          (v) => (v instanceof File || v.isFromSocial) && v.name === file.name,
        ).length === 0
      ) {
        if (verifyAccept(file.type, this.fileInput.getAttribute("accept"))) {
          this.photos.push(file);
        } else {
          alert(`Photo ${file.name} is an invalid file type.`);
        }
      } else {
        alert(
          "This photo has already been selected. Please do not attempt to upload the same photo twice.",
        );
      }
    });

    if (this.photos.length) {
      this.isSelectingPhotos = false;
    }

    this.render();

    this.fileInput.value = null;
  }

  /**
   * Render the layout-selector frame of the uploader modal.
   */
  renderLayoutFrame() {
    sendBrowserAgnosticEvent(
      this.layoutFrame.querySelector(".deceased-photos-layout-editor"),
      "reloadPhotoLayouts",
    );
  }

  /**
   * Render the filter-too-many-photos frame of the upload modal.
   */
  renderFilterFrame() {
    const tileContainer = this.filterFrame.querySelector(".photo-tile-container");

    // Remove all existing children.
    while (tileContainer.firstChild) {
      tileContainer.removeChild(tileContainer.firstChild);
    }

    this.photos.forEach((photo) => {
      const newTile = document.createElement("div");
      newTile.classList.add("photo-tile");
      newTile.dataset.photoName = photo.name || photo.uuid || photo.hashid;

      const newTilePhoto = document.createElement("img");
      newTilePhoto.classList.add("photo");
      newTile.appendChild(newTilePhoto);

      if (this.cropperData[photo.name]) {
        newTilePhoto.style.objectFit = "";
        newTilePhoto.src = this.cropperData[photo.name].croppedCanvas;
      } else {
        newTilePhoto.style.objectFit = "cover";
        newTilePhoto.style.objectPosition = "center center";

        if (photo instanceof File) {
          const fileReader = new FileReader();
          fileReader.onload = () => {
            newTilePhoto.src = `${fileReader.result}`;
          };
          fileReader.readAsDataURL(photo);
        } else {
          newTilePhoto.src = photo.url || photo.thumbnailUrl;
        }
      }

      const remove = document.createElement("a");
      remove.classList.add("remove-tile-button");
      remove.href = "javascript:;";
      remove.innerHTML = "&times;";
      remove.addEventListener("click", (evt) => {
        this.removePhoto(photo);
        evt.stopPropagation();
      });
      newTile.appendChild(remove);

      tileContainer.appendChild(newTile);
    });

    const canProgress = this.photos.length <= this.collageSize;

    this.filterFrame.querySelector(".alert").classList.toggle("d-none", canProgress);
    this.filterFinishedButton.disabled = !canProgress;
    this.filterFinishedButton.classList.toggle("disabled", !canProgress);
  }

  /**
   * Render the upload preview frame of the upload modal (which allows for cropping and
   * rearrangement).
   */
  renderPreviewFrame() {
    const previewPhotosContainer = this.previewFrame.querySelector(".preview-photos");

    previewPhotosContainer
      .querySelectorAll(".preview-photo.placeholder")
      .forEach((placeholder) => {
        placeholder.remove();
      });

    this.photos.forEach((photo) => {
      const photoName = photo.name || photo.uuid || photo.hashid;
      let photoContainer = previewPhotosContainer.querySelector(
        `[data-photo-name="${photoName}"]`,
      );
      if (photoContainer) {
        if (this.cropperData[photo.name]) {
          const photoImg = photoContainer.querySelector("img.photo");
          photoImg.style.objectFit = "contain";
          photoImg.style.objectPosition = "center center";
          photoImg.src = this.cropperData[photo.name].croppedCanvas;
        }
      } else {
        photoContainer = document.createElement("div");
        photoContainer.classList.add("preview-photo", "memorial-collage-grid-item");
        photoContainer.dataset.photoName = photoName;

        const primaryBanner = document.createElement("span");
        primaryBanner.classList.add("primary-banner");
        primaryBanner.classList.add("badge");
        primaryBanner.classList.add("rounded-pill");
        primaryBanner.classList.add("text-bg-secondary");
        primaryBanner.innerHTML = "PRIMARY PHOTO";
        photoContainer.appendChild(primaryBanner);

        if (photo instanceof File || photo.isFromSocial) {
          const cropControlContainer = document.createElement("div");
          cropControlContainer.classList.add("photo-controls", "crop");
          const cropControl = document.createElement("a");
          cropControl.href = "javascript:;";
          cropControl.classList.add(
            "preview-crop",
            "mt-text-color-secondary",
            "mt-border-color-secondary",
            "border",
          );
          cropControl.innerHTML = "CROP";
          cropControl.addEventListener("click", () => {
            this.cropPhoto(photo);
          });
          cropControlContainer.appendChild(cropControl);
          photoContainer.appendChild(cropControlContainer);
        }

        const moveControls = document.createElement("div");
        moveControls.classList.add("photo-controls", "move");
        photoContainer.appendChild(moveControls);

        const remove = document.createElement("a");
        remove.href = "javascript:;";
        remove.classList.add("preview-remove");
        remove.innerHTML = "REMOVE";
        remove.addEventListener("click", (evt) => {
          this.removePhoto(photo);
          evt.stopPropagation();
        });
        moveControls.appendChild(remove);

        const change = document.createElement("a");
        change.href = "javascript:;";
        change.classList.add("preview-change");
        change.innerHTML = "CHANGE";
        change.addEventListener("click", (evt) => {
          this.removePhoto(photo);
          this.startSelectPhotos();
          evt.stopPropagation();
        });
        moveControls.appendChild(change);

        const moveUp = document.createElement("a");
        moveUp.href = "javascript:;";
        moveUp.innerHTML = "&lt;";
        moveUp.addEventListener("click", () => {
          this.movePhoto(photo, false);
        });
        moveControls.appendChild(moveUp);

        const moveDown = document.createElement("a");
        moveDown.href = "javascript:;";
        moveDown.innerHTML = "&gt;";
        moveDown.addEventListener("click", () => {
          this.movePhoto(photo, true);
        });
        moveControls.appendChild(moveDown);

        const photoImg = document.createElement("img");
        photoImg.classList.add("photo");
        photoContainer.appendChild(photoImg);

        previewPhotosContainer.appendChild(photoContainer);

        if (this.cropperData[photo.name]) {
          photoImg.style.objectFit = "";
          photoImg.src = this.cropperData[photo.name].croppedCanvas;
        } else {
          photoImg.style.objectFit = "cover";
          photoImg.style.objectPosition = "center center";

          if (photo instanceof File) {
            const fileReader = new FileReader();
            fileReader.onload = () => {
              photoImg.src = `${fileReader.result}`;
            };
            fileReader.readAsDataURL(photo);
          } else {
            photoImg.src = photo.url || photo.thumbnailUrl;
          }
        }
      }

      if (this.isOnboarding) {
        photoContainer
          .querySelector(".preview-remove")
          .classList.toggle("d-none", this.photos.length <= 1);
      }
    });

    let placeholderCount = this.collageSize - this.photos.length;
    if (placeholderCount > 0) {
      if (this.isOnboarding) {
        placeholderCount = 1;
      }

      for (let placeholder = 0; placeholder < placeholderCount; placeholder += 1) {
        const placeholderElement = document.createElement("a");
        placeholderElement.setAttribute("href", "javascript:;");
        placeholderElement.classList.add(
          "preview-photo",
          "placeholder",
          "memorial-collage-grid-item",
        );
        placeholderElement.addEventListener("click", () => {
          this.startSelectPhotos();
        });

        const cameraImg = document.createElement("img");
        cameraImg.setAttribute("src", this.container.dataset.cameraSvg);

        placeholderElement.append(cameraImg, "Add Photo(s)");

        previewPhotosContainer.append(placeholderElement);
      }
    }

    this.incompleteWarning.classList.toggle(
      "d-none",
      this.isOnboarding || placeholderCount <= 0,
    );
    if (!this.isOnboarding) {
      this.saveButton.classList.toggle("disabled", placeholderCount !== 0);
      if (placeholderCount === 0) {
        this.saveButton.removeAttribute("disabled");
        this.saveButton.innerText = "Save Changes";
      } else {
        this.saveButton.setAttribute("disabled", "disabled");
        this.saveButton.innerText = `${placeholderCount} space${placeholderCount === 1 ? "" : "s"} left`;
      }
    }
  }

  /**
   * Invoke the photo selector/importer UX (upload/stock/FB/etc)
   */
  startSelectPhotos() {
    this.isSelectingPhotos = true;
    this.render();
  }

  /**
   * Actually do the upload of the photos. We have to use XMLHttpRequest since fetch
   * does not support progress events/updates for uploads.
   *
   * @returns {Promise<void>} - void Promise of the upload in-progress
   */
  async uploadPhotos() {
    const formData = new FormData();

    Object.keys(this.uploadExtraParams).forEach((k) => {
      formData.set(k, this.uploadExtraParams[k]);
    });

    this.photos.forEach((photo, idx) => {
      if (photo instanceof File) {
        formData.append("photos", photo);

        let photoMetadata = {
          is_main_photo: idx === 0,
          idx,
        };

        if (this.cropperData[photo.name]) {
          const cropperData = this.cropperData[photo.name].cropData;
          photoMetadata = {
            x: cropperData.x,
            y: cropperData.y,
            width: cropperData.width,
            height: cropperData.height,
            ...photoMetadata,
          };
        }

        formData.set(photo.name, JSON.stringify(photoMetadata));
      } else if (photo.isFromSocial) {
        const cropperData = this.cropperData[photo.name];

        let photoMetadata = {
          is_main_photo: idx === 0,
          social_url: photo.url,
          idx,
        };

        if (cropperData) {
          photoMetadata = {
            x: cropperData.x,
            y: cropperData.y,
            width: cropperData.width,
            height: cropperData.height,
            ...photoMetadata,
          };
        }

        formData.set(photo.name, JSON.stringify(photoMetadata));
      } else {
        formData.set(
          photo.uuid || photo.hashid,
          JSON.stringify({
            is_main_photo: idx === 0,
            idx,
          }),
        );
      }
    });

    const { uploadOverlay } = this;
    const progressBar = this.uploadOverlay.querySelector(".el-progress > *");
    const uploadCount = this.photos.length;
    const errorAlert = this.previewFrame.querySelector(".alert-warning");

    this.previewFrame.classList.add("uploading");
    uploadOverlay.classList.remove("d-none");
    errorAlert.classList.add("d-none");

    const onProgressPercentage = (percent) => {
      progressBar.value = percent / 2;

      // The second half of the progress is estimating the processing time
      // on the other end. Interval number here is just kind of a magic
      // number that makes the rest of the bar go at a reasonable speed.
      if (percent >= 100) {
        const loadFinish = setInterval(() => {
          if (uploadOverlay.classList.contains("d-none") || !progressBar) {
            clearInterval(loadFinish);
            return;
          }

          const currentValue = parseInt(progressBar.value, 10);
          if (currentValue < 100) {
            progressBar.value = currentValue + 1;
          } else {
            clearInterval(loadFinish);
          }
        }, uploadCount * 40);
      }
    };

    let response;

    try {
      response = await postMultipartWithProgress(
        this.uploadUrl,
        formData,
        onProgressPercentage,
      );
    } catch (error) {
      window.Rollbar.error("Error uploading multi-DeceasedPhotos", error);

      this.previewFrame.classList.remove("uploading");
      uploadOverlay.classList.add("d-none");
      errorAlert.classList.remove("d-none");

      return;
    }

    this.previewFrame.classList.remove("uploading");

    progressBar.value = 0;
    uploadOverlay.classList.add("d-none");

    if (this.nextUrl) {
      window.location.href = this.nextUrl;
    } else if (this.onPhotosUpdated) {
      this.existingPhotos = response.data;
      this.photos = this.existingPhotos.slice();
      this.cropperData = {};

      const previewPhotosContainer = this.previewFrame.querySelector(".preview-photos");
      while (previewPhotosContainer.hasChildNodes()) {
        previewPhotosContainer.removeChild(previewPhotosContainer.lastChild);
      }

      this.isSelectingPhotos = true;
      this.onPhotosUpdated(response.data);
    }

    document
      .querySelectorAll(".deceased-photos-layout-editor")
      .forEach((photoLayoutSelector) => {
        sendBrowserAgnosticEvent(photoLayoutSelector, "reloadPhotoLayouts");
      });

    this.hide();
  }

  /**
   * Move a photo in the layout order.
   *
   * @param {object} photo - the photo data dict from this.photos
   * @param {boolean} [up] - whether to move forward or back in order.
   */
  movePhoto(photo, up = true) {
    const currentIndex = this.photos.indexOf(photo);
    let newIndex = currentIndex + (up ? 1 : -1);
    newIndex = Math.max(Math.min(this.photos.length - 1, newIndex), 0);

    if (currentIndex === newIndex) {
      return;
    }

    this.photos.splice(newIndex, 0, this.photos.splice(currentIndex, 1)[0]);

    const previewElementContainer = this.previewFrame.querySelector(".preview-photos");

    const photoName = photo.name || photo.uuid || photo.hashid;
    const previewElement = previewElementContainer.querySelector(
      `[data-photo-name="${photoName}"]`,
    );

    if (previewElement) {
      if (up) {
        previewElementContainer.insertBefore(
          previewElement,
          previewElement.nextSibling ? previewElement.nextSibling.nextSibling : null,
        );
      } else if (currentIndex > 0) {
        previewElementContainer.insertBefore(
          previewElement,
          previewElement.previousSibling,
        );
      }
    }
  }

  /**
   * Remove a photo from the layout.
   *
   * @param {object} photo - the photo data dict from this.photos
   */
  removePhoto(photo) {
    this.photos = this.photos.filter((p) => p !== photo);

    const photoName = photo.name || photo.uuid || photo.hashid;

    const previewElement = this.previewFrame.querySelector(
      `[data-photo-name="${photoName}"]`,
    );
    if (previewElement) {
      previewElement.remove();
    }

    if (this.photos.length) {
      if (this.isFiltering) {
        this.renderFilterFrame();
      } else {
        this.renderPreviewFrame();
      }
    } else {
      this.render();
    }
  }

  /**
   * Initialize and show the crop UX for the given photo
   *
   * @param {object} photo - the photo data dict from this.photos
   */
  cropPhoto(photo) {
    let cropper;

    const cropperContainer = document.createElement("div");
    cropperContainer.classList.add(
      "cropper-dvh100",
      "position-fixed",
      "top-0",
      "left-0",
      "w-100",
      "h-100",
      "d-flex",
      "justify-content-stretch",
      "align-items-stretch",
      "flex-column",
      "bg-black",
    );
    cropperContainer.style.zIndex = "9998"; // Absolute highest z-index, trumps everything, except zoom buttons
    const cropperImg = document.createElement("img");
    cropperImg.classList.add("flex-shrink-1");
    cropperImg.style.objectFit = "contain";
    cropperImg.style.objectPosition = "center center";
    cropperImg.style.flexBasis = "calc(100% - 74px)";
    cropperImg.style.maxHeight = "calc(100% - 74px)";

    const zoomControls = document.createElement("div");
    zoomControls.classList.add(
      "zoom-controls",
      "position-absolute",
      "top-0",
      "right-0",
      "d-flex",
      "m-3",
    );
    zoomControls.style.zIndex = "9999";

    const zoomInBtn = document.createElement("a");
    zoomInBtn.setAttribute("href", "javascript:;");
    zoomInBtn.classList.add(
      "zoom-control",
      "zoom-in",
      "rounded-circle",
      "fw-bold",
      "text-black",
      "bg-white",
      "d-flex",
      "align-items-center",
      "justify-content-center",
      "m-1",
    );
    zoomInBtn.style.width = "40px";
    zoomInBtn.style.height = "40px";
    zoomInBtn.style.fontSize = "36px";
    zoomInBtn.style.lineHeight = "36px";
    zoomInBtn.innerText = "\uFF0B";
    zoomInBtn.addEventListener("click", () => {
      if (cropper) {
        cropper.zoom(0.1);
      }
    });

    const zoomOutBtn = document.createElement("a");
    zoomOutBtn.setAttribute("href", "javascript:;");
    zoomOutBtn.classList.add(
      "zoom-control",
      "zoom-in",
      "rounded-circle",
      "fw-bold",
      "text-black",
      "bg-white",
      "d-flex",
      "align-items-center",
      "justify-content-center",
      "m-1",
    );
    zoomOutBtn.style.width = "40px";
    zoomOutBtn.style.height = "40px";
    zoomOutBtn.style.fontSize = "36px";
    zoomOutBtn.style.lineHeight = "36px";
    zoomOutBtn.innerText = "\uFF0D";
    zoomOutBtn.addEventListener("click", () => {
      if (cropper) {
        cropper.zoom(-0.1);
      }
    });

    zoomControls.append(zoomOutBtn, zoomInBtn);

    cropperContainer.appendChild(zoomControls);

    // Restore prior crop if applicable.
    let canvasData;
    if (this.cropperData[photo.name]) {
      canvasData = this.cropperData[photo.name].canvasData;
    }

    const cropOptions = {
      ...CROPPER_OPTIONS,
      ready() {
        if (canvasData) {
          this.cropper.setCanvasData(canvasData);
        } else {
          // Get image dimensions and crop-box dimensions, and find the direction we need
          // to zoom in to "fill" the crop box.
          const imageData = cropper.getImageData();
          const cropData = cropper.getCropBoxData();

          cropper.zoom(
            Math.max(
              cropData.width / imageData.width,
              cropData.height / imageData.height,
            ) - 1.0,
          );
        }
      },
    };

    if (photo instanceof File) {
      const fileReader = new FileReader();
      fileReader.onload = () => {
        cropperImg.src = `${fileReader.result}`;
        cropper = new Cropper(cropperImg, cropOptions);
      };

      fileReader.readAsDataURL(photo);
    } else {
      cropperImg.src = photo.url || photo.thumbnailUrl;
      cropper = new Cropper(cropperImg, cropOptions);
    }

    cropperContainer.appendChild(cropperImg);

    const buttonBar = document.createElement("div");
    buttonBar.classList.add(
      "w-100",
      "flex-grow-1",
      "d-flex",
      "justify-content-between",
      "bg-white",
      "p-2",
    );

    const cancelButton = document.createElement("a");
    cancelButton.href = "javascript:;";
    cancelButton.addEventListener("click", () => {
      cropperContainer.remove();
    });
    cancelButton.classList.add("btn", "btn-lg", "btn-outline-danger");
    cancelButton.style.minWidth = "0";
    cancelButton.innerText = "Cancel";
    buttonBar.appendChild(cancelButton);

    const saveButton = document.createElement("a");
    saveButton.href = "javascript:;";
    saveButton.addEventListener("click", () => {
      this.cropperData[photo.name] = {
        canvasData: cropper.getCanvasData(),
        cropData: cropper.getData(),
        croppedCanvas: cropper.getCroppedCanvas().toDataURL("image/jpeg"),
      };
      cropperContainer.remove();
      this.render();
    });

    saveButton.classList.add("btn", "btn-lg", "btn-primary");
    saveButton.style.minWidth = "0";
    saveButton.innerText = "Crop";

    buttonBar.appendChild(saveButton);
    cropperContainer.appendChild(buttonBar);

    document.body.appendChild(cropperContainer);
  }

  /**
   * Called when social import photos are received.
   *
   * @param {Array} photos - an array of photo data from the social provider.
   */
  onSocialPhotosSelected(photos) {
    if (photos.length) {
      this.isSelectingPhotos = false;
      this.isInExternalPhotoSelection = false;
      this.photos.push(
        ...photos.map((url) => ({ url, name: url, isFromSocial: true })),
      );
    } else {
      this.isSelectingPhotos = true;
      this.isInExternalPhotoSelection = true;
    }

    this.render();
  }
}
