import Modal from "bootstrap/js/dist/modal";

export const SocialSource = {
  FACEBOOK: "facebook",
};

SocialSource.display = (value) => {
  switch (value) {
    case SocialSource.FACEBOOK:
      return "Facebook";
    default:
      return "Somewhere";
  }
};

class FacebookImporter {
  constructor(mediaImporter) {
    this.mediaImporter = mediaImporter;
    this.auth = null;

    this.nextCursor = null;
    this.nextAlbumCursor = null;

    this.selectedAlbum = null;
  }

  getAlbumsUrl() {
    const baseUrl = "/me/albums?fields=id,images,name,picture";

    return this.nextAlbumCursor ? `${baseUrl}&after=${this.nextAlbumCursor}` : baseUrl;
  }

  getMediaUrl() {
    const baseUrl = this.selectedAlbum
      ? `/${this.selectedAlbum.id}/photos?fields=id,images,name,picture`
      : "/me/photos/uploaded?fields=id,images,name,picture";

    return this.nextCursor ? `${baseUrl}&after=${this.nextCursor}` : baseUrl;
  }

  authenticate(next, authType = null) {
    const options = {
      return_scopes: true,
      scope: "public_profile,user_photos",
    };

    if (authType) {
      options.auth_type = authType;
    }

    window.FB.login((response) => {
      if (response.status === "connected") {
        if (response.authResponse.grantedScopes.includes("user_photos")) {
          this.auth = response.authResponse;
          if (this.auth) {
            next();
          } else {
            this.mediaImporter.error(
              "There was a problem with your login. Please try again.",
            );
          }
        } else {
          this.mediaImporter.error(
            "You denied permission to your photos. Please try again and be sure to allow photos permissions.",
          );
        }
      } else {
        this.mediaImporter.error(
          "An error occurred connecting to your Facebook account. Please try again.",
        );
      }
    }, options);
  }

  loadAlbums() {
    this.mediaImporter.loading();

    if (this.auth) {
      window.FB.api(this.getAlbumsUrl(), (response) => {
        if (response && !response.error) {
          this.nextAlbumCursor =
            response.data.length === 25 &&
            response.paging &&
            response.paging.cursors &&
            response.paging.cursors.after;
          this.mediaImporter.albumsReceived(
            response.data.map((node) => ({
              id: node.id,
              name: node.name,
              thumbnail: node.picture.data.url,
            })),
          );
        } else {
          this.mediaImporter.error(response.error);
        }
      });
    } else {
      this.authenticate(() => {
        this.loadAlbums();
      });
    }
  }

  selectAlbum(album) {
    this.media = [];
    this.selectedAlbum = album;
  }

  loadMedia() {
    this.mediaImporter.loading();

    if (this.auth) {
      window.FB.api(this.getMediaUrl(), (response) => {
        if (response && !response.error) {
          this.nextCursor =
            response.data.length === 25 &&
            response.paging &&
            response.paging.cursors &&
            response.paging.cursors.after;

          this.mediaImporter.mediaReceived(
            response.data.map((node) => ({
              id: node.id,
              caption: node.name,
              thumbnail: node.picture,
              url: node.images[0].source,
            })),
          );
        } else {
          this.mediaImporter.error(response.error);
        }
      });
    } else {
      this.authenticate(() => {
        this.loadMedia();
      });
    }
  }

  reset() {
    this.nextCursor = null;
    this.nextAlbumCursor = null;
    this.selectedAlbum = null;
  }
}

export class SocialPhotoImporter {
  constructor(root, onPhotosSelected, maxAllowed = 20) {
    // DOM elements
    this.root = root;
    this.modal = Modal.getOrCreateInstance(this.root);
    this.albumList = this.root.querySelector(".album-list");
    this.mediaGrid = this.root.querySelector(".media-grid");
    this.selectedMediaGrid = this.root.querySelector(".selected-media-grid");
    this.errorContainer = this.root.querySelector(".overlay.error");
    this.loadingContainer = this.root.querySelector(".overlay.loading");

    this.loadingMoreContainer = this.mediaGrid.querySelector(".loading-more");
    this.loadMoreMedia = this.mediaGrid.querySelector(".load-more");

    this.loadingMoreAlbumsContainer = this.albumList.querySelector(".loading-more");
    this.loadMoreAlbums = this.albumList.querySelector(".load-more");

    // Media state
    this.albums = [];
    this.selectedAlbum = null;

    this.media = [];
    this.selectedMedia = [];

    // Importer state
    this.facebookImporter = new FacebookImporter(this);
    this.activeImporter = null;

    // Other
    this.maximum = maxAllowed;
    this.root.querySelector(".max-allowed").innerText = `${this.maximum}`;

    this.root.addEventListener("hidden.bs.modal", () => {
      this.reset();
    });

    this.mediaGrid.querySelector(".album-back").addEventListener("click", () => {
      this.selectAlbum(null);
    });

    this.root.querySelector(".import-photos-submit").addEventListener("click", () => {
      this.hide();
      onPhotosSelected(this.selectedMedia.map((media) => media.url));
    });

    this.loadMoreMedia.addEventListener("click", () => {
      if (this.activeImporter) {
        this.activeImporter.loadMedia();
      }
    });

    this.loadMoreAlbums.addEventListener("click", () => {
      if (this.activeImporter && this.activeImporter.loadAlbums) {
        this.activeImporter.loadAlbums();
      }
    });
  }

  show(source) {
    if (source === SocialSource.FACEBOOK) {
      this.activeImporter = this.facebookImporter;
    }

    if (this.activeImporter) {
      if (this.activeImporter.loadAlbums) {
        this.activeImporter.loadAlbums();
      } else {
        this.activeImporter.loadMedia();
      }

      this.root.querySelector(".source-name").innerText = SocialSource.display(source);
      this.modal.show();
    }
  }

  hide() {
    this.modal.hide();
  }

  resetMedia() {
    this.media = [];

    if (this.activeImporter && this.activeImporter.nextCursor) {
      this.activeImporter.nextCursor = null;
    }
  }

  reset() {
    this.albums = [];
    this.selectedAlbum = null;
    this.selectedMedia = [];

    if (this.activeImporter) {
      this.activeImporter.reset();
    }

    this.render();
  }

  retry() {
    if (this.activeImporter) {
      if (this.activeImporter.auth) {
        if (this.selectedAlbum || !this.activeImporter.loadAlbums) {
          this.activeImporter.loadMedia();
        } else {
          this.activeImporter.loadAlbums();
        }
      } else {
        this.activeImporter.authenticate(() => {
          if (this.activeImporter.loadAlbums) {
            this.activeImporter.loadAlbums();
          } else {
            this.activeImporter.loadMedia();
          }
        }, "rerequest");
      }
    }
  }

  mediaReceived(media) {
    this.media.push(...media);
    this.render();
  }

  albumsReceived(albums) {
    this.albums.push(...albums);
    this.render();
  }

  render() {
    const existingAlbumIds = this.albums.map((a) => `${a.id}`);
    this.albumList.querySelectorAll(".album").forEach((node) => {
      if (!existingAlbumIds.includes(node.id)) {
        node.remove();
      }
    });

    this.albums.forEach((album) => {
      const existingAlbumElement = document.getElementById(album.id);

      if (!existingAlbumElement) {
        const newAlbumElement = document.createElement("a");
        newAlbumElement.href = "javascript:;";
        newAlbumElement.classList.add("album");
        const newAlbumThumb = document.createElement("img");
        newAlbumThumb.classList.add("thumbnail");
        newAlbumThumb.src = album.thumbnail;
        const newAlbumTitle = document.createElement("div");
        newAlbumTitle.classList.add("title");
        newAlbumTitle.innerText = album.name;

        newAlbumElement.appendChild(newAlbumThumb);
        newAlbumElement.appendChild(newAlbumTitle);

        newAlbumElement.addEventListener("click", () => {
          this.selectAlbum(album);
        });

        this.albumList.insertBefore(newAlbumElement, this.loadingMoreAlbumsContainer);
      }
    });

    const existingIds = this.media.map((m) => `${m.id}`);
    this.mediaGrid.querySelectorAll(".media").forEach((node) => {
      if (!existingIds.includes(node.id)) {
        node.remove();
      }
    });

    this.media.forEach((media) => {
      const existingMediaElement = document.getElementById(media.id);
      const isSelected = this.selectedMedia.filter((m) => m.id === media.id).length;

      if (existingMediaElement) {
        existingMediaElement.classList.toggle("selected", isSelected);
      } else {
        const newMediaElement = document.createElement("div");
        newMediaElement.classList.add("media");
        newMediaElement.id = media.id;
        newMediaElement.classList.toggle("selected", isSelected);

        const newMediaImg = document.createElement("img");
        newMediaImg.classList.add("media-img");
        newMediaImg.src = media.thumbnail;
        newMediaImg.alt = media.caption;

        newMediaElement.addEventListener("click", () => {
          this.toggleSelected(media);
        });
        newMediaElement.appendChild(newMediaImg);

        this.mediaGrid.insertBefore(newMediaElement, this.loadingMoreContainer);
      }
    });

    const existingSelectedIds = this.selectedMedia.map((m) => `${m.id}-selected`);
    this.selectedMediaGrid.querySelectorAll(".selected-media").forEach((node) => {
      if (!existingSelectedIds.includes(node.id)) {
        node.remove();
      }
    });

    this.selectedMedia.forEach((selectedMedia) => {
      const selectedId = `${selectedMedia.id}-selected`;

      const existingSelectedMediaElement = document.getElementById(selectedId);
      if (!existingSelectedMediaElement) {
        const newSelected = document.createElement("img");
        newSelected.src = selectedMedia.thumbnail;
        newSelected.classList.add("selected-media");
        newSelected.id = selectedId;
        newSelected.addEventListener("click", () => {
          this.toggleSelected(selectedMedia);
        });
        this.selectedMediaGrid.appendChild(newSelected);
      }
    });

    const shouldChooseAlbum = this.albums.length && !this.selectedAlbum;
    this.albumList.classList.toggle("d-none", !shouldChooseAlbum);
    this.mediaGrid.classList.toggle("d-none", shouldChooseAlbum);

    this.root
      .querySelector(".selected-media-container")
      .classList.toggle("d-none", !this.selectedMedia.length);
    this.root.querySelector(".selected-media-header .count").innerText =
      `${this.selectedMedia.length} Photo${this.selectedMedia.length === 1 ? "" : "s"}`;
    this.mediaGrid.classList.toggle(
      "maximum-selected",
      this.selectedMedia.length >= this.maximum,
    );

    this.loadMoreMedia.classList.toggle("d-none", !this.activeImporter.nextCursor);
    this.loadMoreAlbums.classList.toggle(
      "d-none",
      !this.activeImporter.nextAlbumCursor,
    );

    this.loading(false);
  }

  selectAlbum(album) {
    this.selectedAlbum = album;
    this.resetMedia();

    if (this.selectedAlbum && this.selectedAlbum.children) {
      this.mediaGrid.querySelector(".album-title").classList.toggle("d-none", !album);
      this.mediaGrid.querySelector(".title-value").innerText =
        `${this.selectedAlbum.children.length} photos`;
      this.media = album.children;
    } else {
      this.mediaGrid.querySelector(".album-title").classList.toggle("d-none", !album);
      this.mediaGrid.querySelector(".title-value").innerText = album ? album.name : "";

      if (this.activeImporter.selectAlbum) {
        this.activeImporter.selectAlbum(this.selectedAlbum);
      } else if (!this.albums.length) {
        this.activeImporter.loadMedia();
      }

      if (this.selectedAlbum) {
        this.activeImporter.loadMedia();
      }
    }

    this.render();
  }

  toggleSelected(media) {
    if (this.selectedMedia.includes(media)) {
      this.selectedMedia = this.selectedMedia.filter((m) => m.id !== media.id);
    } else if (this.selectedMedia.length < this.maximum) {
      this.selectedMedia.push(media);
    }

    this.render();
  }

  loading(show = true) {
    if (!show) {
      this.loadingMoreContainer.classList.add("d-none");
      this.loadingMoreAlbumsContainer.classList.add("d-none");
      this.loadingContainer.classList.add("d-none");
    } else if ((this.media && this.media.length) || this.selectedAlbum) {
      this.loadingMoreContainer.classList.remove("d-none");
    } else if (this.albums && this.albums.length && !this.selectedAlbum) {
      this.loadingMoreAlbumsContainer.classList.remove("d-none");
    } else {
      this.loadingContainer.classList.remove("d-none");
    }
  }

  error(message, allowRetry = true) {
    this.loading(false);

    if (message) {
      this.errorContainer.querySelector(".message").innerText = message;
      this.errorContainer
        .querySelector(".retry")
        .classList.toggle("d-none", !allowRetry);
    }

    this.errorContainer.classList.toggle("d-none", !message);
  }
}
